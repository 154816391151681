:root {
  --primary-color: #1ab394;
}

.App {
  text-align: center;
  background-color: #2f4050;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}

.App-header {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  flex-direction: column;
  background: #dff1ff00;
  /* backdrop-filter: blur(15px); */
  align-items: center;
  justify-content: center;
  transition: 0.3s all ease-in-out;
  top: 0;
  left: 0;
  z-index: 1;
}

.App-header:hover {
  top: 0;
  box-shadow: 0 0 20px rgba(0, 53, 102, 0.2);
}


.App-header .logo,
.App-header .input-group,
.App-header .btn-group {
  margin: 10px 0;
  display: flex;
}

.App-header .input-group {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 60%;
  min-width: 40%;
  /* width: max-content; */
}

.App-header .input-group .label {
  background-color: var(--primary-color);
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 18px;
  position: absolute;
  width: 60px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid var(--primary-color);
  left: 2px;
}

.App-header .logo {
  color: var(--primary-color);
  font-size: 80px;
  font-weight: bold;
  letter-spacing: 10px;
  /* margin: 0 20px; */
}

.App-header .btn-group {
  /* margin: 0 20px; */
}

.download-btn,
.download-btn button,
.preview-btn {
  margin: 0 10px;
  height: 40px;
  border-radius: 4px;
  border: 2px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  padding: 0 20px;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 1rem;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-btn svg,
.download-btn button svg,
.preview-btn svg {
  fill: var(--primary-color);
  margin-right: 5px;
}

.download-btn:focus-visible button,
.download-btn button:focus-visible button,
.preview-btn:focus-visible,
.download-btn button:hover,
.download-btn:hover,
.preview-btn:hover {
  background-color: var(--primary-color);
  color: rgb(223, 241, 255);
}

.download-btn:focus-visible button svg,
.download-btn button:focus-visible button svg,
.preview-btn:focus-visible svg,
.download-btn button:hover svg,
.download-btn:hover svg,
.preview-btn:hover svg {
  fill: white;
}

.App-header .input-group label div,
.App-header .input-group .url {
  height: 40px;
  background: transparent;
  color: rgb(206, 206, 206);
  border: 2px solid var(--primary-color);
  transition: 0.2s border ease-in-out, 0.2s color ease-in-out;
  padding: 0 15px 0 75px;
  width: 100%;
  font-size: 1rem;
  border-radius: 4px;
}

.preview-btn:focus-visible,
.download-btn :focus-visible,
.download-btn button:focus-visible,
.App-header .input-group .url:focus {
  outline: none;
}

.disabled-btn {
  opacity: 0.5;
}

.disabled-btn button:hover,
.disabled-btn:hover {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  cursor: not-allowed;
}

.disabled-btn button:hover svg,
.disabled-btn:hover svg {
  fill: var(--primary-color);
}


.App-header .input-group .url:-internal-autofill-selected {
  background-color: rgba(0, 53, 88, 0.5) !important;
}

.App-header .input-group .url::placeholder {
  color: rgb(172, 172, 172);
}

.App-link {
  color: #61dafb;
}

.body {
  position: fixed;
  top: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.534);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s all ease-in-out;

}

.preview-invoice {
  opacity: 1;
  visibility: visible;
  z-index: 5;
}

.document-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .react-pdf__Document {
  width: 100%;
  height: auto;
} */

/* .document-wrapper:hover .page-controller {
  visibility: visible;
  opacity: 1;
} */

.page-controller {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.568);
  color: white;
  backdrop-filter: blur(3px);
  transition: 0.2s visibility ease-in-out, 0.2s opacity ease-in-out;
  box-shadow: 0 20px 50px rgba(59, 59, 59, 0.411);
  border-radius: 5px;
  overflow: hidden;
  width: max-content;
  position: absolute;
  height: auto;
  border: none;
  opacity: 1;
  z-index: 10;
  bottom: 10px;
  visibility: visible;
}

.landscape .page-controller {}

.portrait .page-controller {
  top: -71px;
  left: 15px;
}

/* .show-page-controls {
  opacity: 1;
  visibility: visible;
}

.hide-page-controls {
  opacity: 0;
  visibility: hidden;
} */

.body .react-pdf__Document {
  display: flex;
  justify-content: center;
  cursor: grabbing;
  text-rendering: optimizeSpeed;
  text-rendering: geometricPrecision;
  -webkit-backface-visibility: hidden;
  -ms-transform: translateZ(0);
  /* IE 9 */
  -webkit-transform: translateZ(0);
  /* Chrome, Safari, Opera */
  transform: translateZ(0);
}

.react-pdf__Document svg {
  text-rendering: optimizeSpeed;
  text-rendering: geometricPrecision;
  -webkit-backface-visibility: hidden;
  -ms-transform: translateZ(0);
  /* IE 9 */
  -webkit-transform: translateZ(0);
  /* Chrome, Safari, Opera */
  transform: translateZ(0);
}

.page-controller p {
  padding: 0 15px;
}

.page-controller button,
.page-controller p {
  border: none;
  background: transparent;
  margin: 0;
  height: 45px;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  transition: 0.2s all ease-in-out;
}

.page-controller button {
  width: 45px;
}

.page-controller button {
  cursor: pointer;
  color: white;
}

.page-controller button:disabled {
  cursor: not-allowed;
  /* color: #2f4050;
  background-color: white; */
  opacity: 0.2;
}

.page-controller button:disabled:hover {
  /* background-color: white; */
}

.page-controller button:hover {
  background-color: rgba(168, 168, 168, 0.411);
}

.not-found {
  width: 100vw;
  height: 100vh;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgb(190, 220, 255);
  font-size: 50px;
  color: rgb(0, 56, 102);
}

.download-button {
  right: 90px;
}

.close-button {
  right: 20px;
}

.download-button,
.close-button {
  top: 20px;
  width: 50px;
  opacity: 0.5;
  height: 50px;
  /* box-shadow: -2px 2px 3px grey; */
  border-radius: 5px;
  background: black;
  cursor: pointer;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s opacity ease-in-out;
  z-index: 200;
}

.download-button svg,
.close-button svg {
  fill: white;
}

.download-button:hover,
.close-button:hover {
  opacity: 1;
}

.error {
  color: red;
  font-size: 1.5rem;
  margin: 10px 0;
}

.footer-links {
  min-height: 50px;
  position: fixed;
  width: 100vw;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  background: #00000024;
}

.footer-links a {
  text-decoration: none !important;
  color: var(--primary-color);
  padding: 0 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1000px) {
  .App-header .logo {
    font-size: 80px;
    letter-spacing: 5px;
  }
}

@media (max-width: 480px) {
  .App-header .logo {
    font-size: 40px;
    letter-spacing: 2px;
  }

  .download-btn,
  .preview-btn {
    padding: 0 10px;
  }
}

/* ------------------------------------------ loader css -------------------------------------------- */

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: var(--primary-color);
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }

  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}